
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatFieldValue2(field, fieldIndex, showVehicleSelector, fieldValue, fieldValueIndex) {
                    return [_createElement('div', mergeProps({
                            'className': 'fitment-cell__value',
                            'key': '66801'
                        }, { dangerouslySetInnerHTML: { __html: fieldValue } }))];
                }
                function scopeShowVehicleSelector3(field, fieldIndex) {
                    var showVehicleSelector = this.vehicleSelector && field === 'Year';
                    return [
                        showVehicleSelector ? [[this.vehicleSelector(function () {
                                    return _createElement('div', { 'className': 'cm_vehicleSelector' }, [this.yearsSelect(function () {
                                            function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                            }
                                            function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                                                var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                                                var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                                                var isPopularField = popularEntries.length && unpopularEntries.length;
                                                return [
                                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                        'value': '',
                                                        'key': 'null-option'
                                                    }, '\n          ', this.title, '\n        ') : null,
                                                    this.loading ? _createElement('option', {
                                                        'key': 'loading-option',
                                                        'disabled': true
                                                    }, '...loading...') : null,
                                                    isPopularField ? [
                                                        _createElement('option', {
                                                            'className': 'cm_option_title',
                                                            'disabled': true,
                                                            'key': '10821'
                                                        }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                                        _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                                        _createElement('option', {
                                                            'className': 'cm_option_title',
                                                            'disabled': true,
                                                            'key': '10824'
                                                        }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                                    ] : null,
                                                    _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                                                ];
                                            }
                                            function scopeShowSelectedValues4() {
                                                var showSelectedValues = this.selectedEntries.length;
                                                return _createElement('option', {
                                                    'key': '_current',
                                                    'value': '_current'
                                                }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                                            }
                                            function repeatEntry5(entry, index) {
                                                return _createElement('div', {
                                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                                    'key': entry.value,
                                                    'onClick': () => this.onChange(entry.term)
                                                }, this.showCheckboxes ? _createElement('input', {
                                                    'type': 'checkbox',
                                                    'readOnly': true,
                                                    'checked': entry.selected,
                                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                                    'key': '3417'
                                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                                    'className': 'cm_dropdown_hitCount',
                                                    'key': '3619'
                                                }, entry.hitCount) : null);
                                            }
                                            return _createElement('div', { 'className': 'cm_yearsSelect' }, _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                                'key': '67'
                                            }, _createElement('select', {
                                                'className': 'cm_select_inner-select cm_select__pretty',
                                                'onChange': this.onChange,
                                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                                'disabled': this.disabled,
                                                'aria-label': this.title
                                            }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                                        return _createElement('div', { 'className': 'form-input cm_filterInput' });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                                'div',
                                                {
                                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                                    'aria-label': this.title,
                                                    'key': '2727'
                                                },
                                                !this.hideNullOption ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'onClick': () => this.onChange('')
                                                }, '\n      ', this.title, '\n    ') : null,
                                                this.loading ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'disabled': true
                                                }, '...loading...') : null,
                                                !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                                            ]) : null));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], _createElement('button', { 'onClick': this.selectVehicle }, 'View All Parts'));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null,
                        !showVehicleSelector ? _map(`${ window.themeSettings?.locales?.ymm[this[field]?.toLowerCase()] || this[field] }`.split('\n'), repeatFieldValue2.bind(this, field, fieldIndex, showVehicleSelector)) : null
                    ];
                }
                function repeatField4(field, fieldIndex) {
                    return _createElement('div', {
                        'className': 'fitment-cell fitment-field-' + field.toLowerCase(),
                        'key': this[field]
                    }, this[field] && this[field] !== 'Universal' ? scopeShowVehicleSelector3.apply(this, [
                        field,
                        fieldIndex
                    ]) : null);
                }
                return _createElement.apply(this, [
                    'div',
                    { 'className': 'fitment-row' },
                    _map(this.fields, repeatField4.bind(this))
                ]);
            }, { count: undefined })];
    }
    function repeatItems2(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatFieldValue2(field, fieldIndex, showVehicleSelector, fieldValue, fieldValueIndex) {
                    return [_createElement('div', mergeProps({
                            'className': 'fitment-cell__value',
                            'key': '68451'
                        }, { dangerouslySetInnerHTML: { __html: fieldValue } }))];
                }
                function scopeShowVehicleSelector3(field, fieldIndex) {
                    var showVehicleSelector = this.vehicleSelector && field === 'Year';
                    return _createElement.apply(this, [
                        'div',
                        { 'className': 'fitment-cell' },
                        showVehicleSelector ? [[this.vehicleSelector(function () {
                                    return _createElement('div', { 'className': 'cm_vehicleSelector' }, [this.yearsSelect(function () {
                                            function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                            }
                                            function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                                                var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                                                var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                                                var isPopularField = popularEntries.length && unpopularEntries.length;
                                                return [
                                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                        'value': '',
                                                        'key': 'null-option'
                                                    }, '\n          ', this.title, '\n        ') : null,
                                                    this.loading ? _createElement('option', {
                                                        'key': 'loading-option',
                                                        'disabled': true
                                                    }, '...loading...') : null,
                                                    isPopularField ? [
                                                        _createElement('option', {
                                                            'className': 'cm_option_title',
                                                            'disabled': true,
                                                            'key': '10821'
                                                        }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                                        _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                                        _createElement('option', {
                                                            'className': 'cm_option_title',
                                                            'disabled': true,
                                                            'key': '10824'
                                                        }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                                    ] : null,
                                                    _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                                                ];
                                            }
                                            function scopeShowSelectedValues4() {
                                                var showSelectedValues = this.selectedEntries.length;
                                                return _createElement('option', {
                                                    'key': '_current',
                                                    'value': '_current'
                                                }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                                            }
                                            function repeatEntry5(entry, index) {
                                                return _createElement('div', {
                                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                                    'key': entry.value,
                                                    'onClick': () => this.onChange(entry.term)
                                                }, this.showCheckboxes ? _createElement('input', {
                                                    'type': 'checkbox',
                                                    'readOnly': true,
                                                    'checked': entry.selected,
                                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                                    'key': '3417'
                                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                                    'className': 'cm_dropdown_hitCount',
                                                    'key': '3619'
                                                }, entry.hitCount) : null);
                                            }
                                            return _createElement('div', { 'className': 'cm_yearsSelect' }, _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                                'key': '67'
                                            }, _createElement('select', {
                                                'className': 'cm_select_inner-select cm_select__pretty',
                                                'onChange': this.onChange,
                                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                                'disabled': this.disabled,
                                                'aria-label': this.title
                                            }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                                        return _createElement('div', { 'className': 'form-input cm_filterInput' });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                                'div',
                                                {
                                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                                    'aria-label': this.title,
                                                    'key': '2727'
                                                },
                                                !this.hideNullOption ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'onClick': () => this.onChange('')
                                                }, '\n      ', this.title, '\n    ') : null,
                                                this.loading ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'disabled': true
                                                }, '...loading...') : null,
                                                !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                                            ]) : null));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], _createElement('button', { 'onClick': this.selectVehicle }, 'View All Parts'));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null,
                        !showVehicleSelector ? _map(`${ window.themeSettings?.locales?.ymm[this[field]?.toLowerCase()] || this[field] }`.split('\n'), repeatFieldValue2.bind(this, field, fieldIndex, showVehicleSelector)) : null
                    ]);
                }
                function repeatField4(field, fieldIndex) {
                    return [this[field] && this[field] !== 'Universal' ? _createElement('div', {
                            'className': 'fitment-row',
                            'key': this[field]
                        }, _createElement('div', { 'className': 'fitment-cell' }, window.themeSettings?.locales?.ymm[field.toLowerCase()] || field), scopeShowVehicleSelector3.apply(this, [
                            field,
                            fieldIndex
                        ])) : null];
                }
                return itemsIndex !== 0 ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'fitment-table__inner-container',
                        'key': '7'
                    },
                    _map(this.fields, repeatField4.bind(this))
                ]) : null;
            }, { count: undefined })];
    }
    return !this.universalFit ? _createElement('div', {
        'className': 'cm_fitment-table fitment-table-container',
        'key': '0'
    }, this.vehicleSpecific ? _createElement('div', {
        'className': 'fitment-table-title vehicle-specific',
        'key': '85'
    }, '\n    This Part Fits:\n  ') : null, this.noFitmentData ? _createElement('div', {
        'className': 'fitment-table-title no-fitment-data',
        'key': '196'
    }, _createElement('div', {}, 'No Fitment Data.'), _createElement('div', {}, '\n      We\'re so sorry about that, but we\'re working on it! We list over 100,000 SKUs and we\'re updating them\n      daily. Some products are uploaded without the fitment guides being built out. If you\'re unsure about\n      product fitment, please email us via\n      ', _createElement('a', { 'href': 'mailto:support@exoperformance.ca' }, 'support@exoperformance.ca'))) : null, this.manyFitments ? _createElement('div', {
        'className': 'fitment-table-title many-fitments',
        'key': '694'
    }, 'Currently displaying 1000 fitments, yet the product has additional fitments.') : null, this.template === 'horizontal' ? _createElement('div', {
        'className': 'cm_fitment-table__horizontal cmTemplate_horizontal',
        'key': '852'
    }, _createElement.apply(this, [
        'div',
        { 'className': 'fitment-table-body cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ])) : null, this.template === 'vertical' ? _createElement('div', {
        'className': 'cm_fitment-table__vertical cmTemplate_vertical',
        'key': '9022'
    }, _createElement.apply(this, [
        'div',
        { 'className': 'fitment-table-body cmRepeater_items' },
        _map(this.items, repeatItems2.bind(this))
    ])) : null) : null;
}
        export const componentNames = ["cm:filterInput","cm:yearsSelect","cm:vehicleSelector","cm:filterInput","cm:yearsSelect","cm:vehicleSelector"]